





































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import Form from '../components/Forms/Form.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import VideoPlayer from '../components/VideoPlayer.vue';

@Component({
  components: {
    SideBySide,
    Form,
    VideoPlayer,
  },
})
export default class Home extends Mixins(View) {
}
