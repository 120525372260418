






















import { Component, Prop, Vue } from 'vue-property-decorator';
import VideoPlayerSource from '../app_code/VideoPlayerSource';

@Component
export default class VideoPlayer extends Vue {
  private active = false

  @Prop({ default: false })
  private autoPlay!: boolean;

  @Prop()
  private readonly ratio!: number;

  @Prop()
  private readonly poster!: string;

  @Prop()
  private readonly video!: VideoPlayerSource;

  @Prop()
  private readonly label!: string;

  mounted() {
    if (this.autoPlay) {
      this.toggleActive();
    }
  }

  toggleActive() {
    this.active = !this.active;

    const vidElem = this.$refs.player as HTMLVideoElement;

    if (this.active) {
      // vidElem.muted = false;

      if (vidElem.currentTime === vidElem.duration) {
        vidElem.currentTime = 0;
      }

      if (vidElem.paused) {
        vidElem.play();
      }
    } else {
      vidElem.pause();
    }
  }
}
